export default {
    service: {
        http: 'account'
    },
    buttons: {
        SIGNIN: 'Efetuar login',
        SIGNUP: 'Criar nova conta',
        FORGOT_PASSWORD: 'Esqueceu a senha?'
    }
};
